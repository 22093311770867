import React, { Component } from 'react'

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col flex-grow p-8">
        <h1 className="text-3xl tracking-wide text-grey-darkest m-4">
          Privacy Statement
        </h1>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What do we do with your information?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          When you purchase something from our store, as part of the buying and
          selling process, we collect the personal information you give us such
          as your name, address and email address.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Email marketing (if applicable): With your permission, we may send you
          emails about our store, new products and other updates.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Consent
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          When you provide us with personal information to complete a
          transaction, verify your credit card, place an order, we imply that
          you consent to our collecting it and using it for that specific reason
          only.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          If we ask for your personal information for a secondary reason, like
          marketing, we will either ask you directly for your expressed consent,
          or provide you with an opportunity to say no.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Disclosure
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          We may disclose your personal information if we are required by law to
          do so or if you violate our Terms of Service.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Payment
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          We use Razorpay for processing payments. We/Razorpay do not store your
          card data on their servers. The data is encrypted through the Payment
          Card Industry Data Security Standard (PCI-DSS) when processing
          payment. Your purchase transaction data is only used as long as is
          necessary to complete your purchase transaction. After that is
          complete, your purchase transaction information is not saved.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Our payment gateway adheres to the standards set by PCI-DSS as managed
          by the PCI Security Standards Council, which is a joint effort of
          brands like Visa, MasterCard, American Express and Discover.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          PCI-DSS requirements help ensure the secure handling of credit card
          information by our store and its service providers.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          For more insight, you may also want to read terms and conditions of
          razorpay on https://razorpay.com
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Third-party Services
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          In general, the third-party providers used by us will only collect,
          use and disclose your information to the extent necessary to allow
          them to perform the services they provide to us.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          However, certain third-party service providers, such as payment
          gateways and other payment transaction processors, have their own
          privacy policies in respect to the information we are required to
          provide to them for your purchase-related transactions.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          For these providers, we recommend that you read their privacy policies
          so you can understand the manner in which your personal information
          will be handled by these providers.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          In particular, remember that certain providers may be located in or
          have facilities that are located a different jurisdiction than either
          you or us. So if you elect to proceed with a transaction that involves
          the services of a third-party service provider, then your information
          may become subject to the laws of the jurisdiction(s) in which that
          service provider or its facilities are located.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Once you leave our store’s website or are redirected to a third-party
          website or application, you are no longer governed by this Privacy
          Policy or our website’s Terms of Service.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          When you click on links on our store, they may direct you away from
          our site. We are not responsible for the privacy practices of other
          sites and encourage you to read their privacy statements.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Security
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          To protect your personal information, we take reasonable precautions
          and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Cookies
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          We use cookies to maintain session of your user. It is not used to
          personally identify you on other websites.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Changes To This Privacy Policy
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website.
        </p>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          If our store is acquired or merged with another company, your
          information may be transferred to the new owners so that we may
          continue to sell products to you.
        </p>
      </div>
    )
  }
}

export default PrivacyPolicy
