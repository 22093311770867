import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import Auth from './components/Auth'

import Home from './pages/Home'
import About from './pages/About'
// import Contact from './pages/Contact'
import Cart from './pages/Cart'
import FAQ from './pages/FAQ'
import PrivacyPolicy from './pages/PrivacyPolicy'
import RefundCancellationPolicy from './pages/RefundCancellationPolicy'
import TermsConditions from './pages/TermsConditions'
import Video from './pages/Video'
import Videos from './pages/Videos'

class App extends Component {
  state = {
    isAuthOpen: false,
    message: 'Login via',
    isMobileMenuOpen: false
  }
  openAuthModal = message => {
    let newState = { isAuthOpen: true }
    if (message) {
      newState.message = message
    }
    this.setState(newState)
  }
  closeAuthModal = () => {
    this.setState({ isAuthOpen: false, message: 'Login via' })
  }
  openMobileMenu = () => {
    this.setState({
      isMobileMenuOpen: true
    })
  }
  closeMobileMenu = () => {
    this.setState({ isMobileMenuOpen: false })
  }
  render() {
    const { isAuthOpen, message, isMobileMenuOpen } = this.state
    return (
      <div className="min-h-screen flex flex-col">
        <Header
          openMobileMenu={this.openMobileMenu}
          openAuthModal={this.openAuthModal}
        />
        {isMobileMenuOpen && (
          <div className="bg-blue-darkest h-screen w-32 absolute z-20 flex flex-col">
            <div className="relative h-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="icon-close w-8 h-8 text-white fill-current absolute pin-r m-4"
                onClick={this.closeMobileMenu}
              >
                <path
                  className="secondary"
                  fillRule="evenodd"
                  d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                />
              </svg>
            </div>
            <NavLink
              to="/about"
              className="text-grey uppercase text-sm font-semibold no-underline tracking-wide m-4 text-right p-2"
              onClick={this.closeMobileMenu}
            >
              About
            </NavLink>
            <button
              onClick={() => {
                this.closeMobileMenu()
                window.Beacon('toggle')
              }}
              className="text-grey uppercase text-sm font-semibold no-underline tracking-wide m-4 text-right p-2"
            >
              Contact
            </button>
            <NavLink
              to="/grades/9"
              className="text-grey uppercase text-sm font-semibold no-underline tracking-wide m-4 text-right p-2"
              onClick={this.closeMobileMenu}
            >
              Grade 9
            </NavLink>
            <NavLink
              to="/grades/10"
              className="text-grey uppercase text-sm font-semibold no-underline tracking-wide m-4 text-right p-2"
              onClick={this.closeMobileMenu}
            >
              Grade 10
            </NavLink>
            <NavLink
              to="/faq"
              className="text-grey uppercase text-sm font-semibold no-underline tracking-wide m-4 text-right p-2"
              onClick={this.closeMobileMenu}
            >
              FAQ
            </NavLink>
          </div>
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/contact" component={Contact} /> */}
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route
            exact
            path="/cart"
            render={props => (
              <Cart openAuthModal={this.openAuthModal} {...props} />
            )}
          />
          <Route exact path="/refunds" component={RefundCancellationPolicy} />
          <Route exact path="/terms" component={TermsConditions} />
          <Route path="/videos/:slug" component={Video} />
          <Route exact path="/grades/:grade" component={Videos} />
        </Switch>
        <Footer />
        <Auth
          message={message}
          isOpen={isAuthOpen}
          closeAuthModal={this.closeAuthModal}
        />
      </div>
    )
  }
}

export default App
