const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const GET_ALL_VIDEOS_FOR_USER = 'GET_ALL_VIDEOS_FOR_USER'

export function login(profile) {
  return {
    type: LOGIN,
    payload: {
      profile,
    },
  }
}

export function logout() {
  return { type: LOGOUT }
}

export function getAllVideosForUser(email) {
  return (dispatch) =>
    fetch(
      `https://lhvgd826fg.execute-api.us-east-1.amazonaws.com/dev/videos/${email}`
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: GET_ALL_VIDEOS_FOR_USER,
          payload: { videos: response.result.videos },
        })
        return response.result.videos
      })
}

const ACTION_HANDLERS = {
  [LOGIN]: (state, action) => {
    return { ...state, isLoggedIn: true, profile: action.payload.profile }
  },
  [LOGOUT]: (state, action) => {
    return { ...state, isLoggedIn: false, profile: {}, videos: [] }
  },
  [GET_ALL_VIDEOS_FOR_USER]: (state, action) => {
    return { ...state, videos: action.payload.videos }
  },
}

const initialState = {
  isLoggedIn: false,
  profile: {},
  videos: [],
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
