import React, { Component } from 'react'
import Modal from 'react-modal'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import { connect } from 'react-redux'

import { login, getAllVideosForUser } from '../redux/user'
import { removeFromCart } from '../redux/cart'

const mapDispatchToProps = { login, getAllVideosForUser, removeFromCart }

class Auth extends Component {
  constructor(props) {
    super(props)
    this.responseGoogle = this.responseGoogle.bind(this)
    this.responseFacebook = this.responseFacebook.bind(this)
  }
  responseFacebook(response) {
    if (response.email) {
      this.props.login({ name: response.name, email: response.email })
      this.props.getAllVideosForUser(response.email).then(videos =>
        videos.map(video =>
          // Videos already bought need to be removed from cart when logging in
          this.props.removeFromCart({
            slug: video
          })
        )
      )
      this.props.closeAuthModal()
      window.Beacon('identify', { name: response.name, email: response.email })
    }
  }

  responseGoogle(response) {
    const { name, email } = response.profileObj
    this.props.login({ name, email })
    this.props.getAllVideosForUser(email).then(videos =>
      videos.map(video =>
        // Videos already bought need to be removed from cart when logging in
        this.props.removeFromCart({
          slug: video
        })
      )
    )
    this.props.closeAuthModal()
    window.Beacon('identify', { name, email })
  }

  render() {
    const { isOpen, closeAuthModal, message } = this.props
    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className="bg-blue-lightest absolute pin flex flex-col justify-start items-center p-32"
        overlayClassName=""
        onRequestClose={closeAuthModal}
      >
        <h2 className="text-2xl font-light text-grey-darkest text-center">
          {message}
        </h2>
        <div className="flex flex-row flex-wrap p-16">
          <FacebookLogin
            appId="2012201405726478"
            fields="name,email,picture"
            className="text-white text-lg font-bold uppercase tracking-wide bg-blue-dark rounded-sm p-4 m-4 w-48"
            callback={this.responseFacebook}
            disableMobileRedirect={true}
          />
          <GoogleLogin
            className="text-white text-lg font-bold uppercase tracking-wide bg-green-dark rounded-sm p-4 m-4 w-48"
            clientId="475456133531-toblmv7090naoeb3b7p77elvnigs88in.apps.googleusercontent.com"
            buttonText="Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
          />
        </div>
        <div className="h-16 absolute pin-t pin-r cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="icon-close w-8 h-8 text-black fill-current m-4"
            onClick={closeAuthModal}
          >
            <path
              className="secondary"
              fillRule="evenodd"
              d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
            />
          </svg>
        </div>
      </Modal>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Auth)
