import React, { Component } from 'react'

class FAQ extends Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col flex-grow p-8">
        <h1 className="text-3xl tracking-wide text-grey-darkest m-4">
          Frequently Asked Questions
        </h1>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What is this online Math course all about?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          This is a crash course specifically tailor-made for the ICSE Math
          Board Exam.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Can I use this if I belong to another board like State boards or the
          CBSE?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          No, this online course is custom made for students of the ICSE Board.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Are the concepts explained?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Yes.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Are all the topics covered?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          No. Relection, VAT and Graphs from Statistics are not included.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What is I am not satisfied with the videos after purchase?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          In the unlikely event that you are not satisfied with the course, we
          are open to suggestions and feedback. However, all purchases are final
          and we do not offer refunds.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What kind of sums are solved from each of the topics?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Typical and central grip questions are solved and explained from each
          of the topics.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What kind of sums are solved from each of the topics?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Typical and central grip questions are solved and explained from each
          of the topics in such a way that if one observes 5 sums in a
          particular video, it is equivalent to getting varied experience of
          solving 10 to 12 sums.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          What is the validity of the my purchase?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Your purchase is valid till the end of the financial year, 31st March
          regardless of the date of purchase.
        </p>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Is there a free trial?
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          Videos of 2 topics are available free of cost.
        </p>
      </div>
    )
  }
}

export default FAQ
