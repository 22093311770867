const ADD_TO_CART = 'ADD_TO_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const CLEAR_CART = 'CLEAR_CART'

export function addToCart(item) {
  return { type: ADD_TO_CART, payload: { item } }
}

export function removeFromCart(item) {
  return { type: REMOVE_FROM_CART, payload: { item } }
}

export function checkoutCart(total, redirectOnSuccess) {
  return (dispatch, getState) => {
    const { user, cart } = getState()
    var options = {
      key: 'rzp_live_Vd3WEWkuMgaDDt',
      amount: total * 100,
      name: 'JB Tutorials',
      image: 'logo.png',
      handler: razorpayResponse => {
        return fetch(
          'https://lhvgd826fg.execute-api.us-east-1.amazonaws.com/dev/process-payment',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              razorpayPaymentID: razorpayResponse.razorpay_payment_id,
              amount: total * 100,
              email: user.profile.email,
              videos: cart.items.map(item => item.slug)
            })
          }
        ).then(response => {
          fetch(
            `https://lhvgd826fg.execute-api.us-east-1.amazonaws.com/dev/videos/${
              user.profile.email
            }`
          )
            .then(response => response.json())
            .then(response => {
              dispatch({
                type: 'GET_ALL_VIDEOS_FOR_USER',
                payload: { videos: response.result.videos }
              })
              dispatch({ type: CLEAR_CART })
              redirectOnSuccess()
              return response
            })
        })
      },
      prefill: { name: user.profile.name, email: user.profile.email },
      theme: { color: '#071224' }
    } // Razorpay accepts amount in paise
    var rzp1 = new window.Razorpay(options)
    rzp1.open()
  }
}

const ACTION_HANDLERS = {
  [ADD_TO_CART]: (state, action) => {
    return {
      items: [...state.items, action.payload.item]
    }
  },
  [REMOVE_FROM_CART]: (state, action) => {
    return {
      items: state.items.filter(item => item.slug !== action.payload.item.slug)
    }
  },
  [CLEAR_CART]: (state, action) => {
    return {
      items: []
    }
  }
}

const initialState = {
  items: []
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
