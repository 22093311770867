import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

const mapStateToProps = ({ videos, user }) => ({
  videos,
  isLoggedIn: user.isLoggedIn,
  purchasedVideos: user.videos
})

class Videos extends Component {
  render() {
    const { isLoggedIn, purchasedVideos, match } = this.props

    const videos = this.props.videos.filter(video => {
      console.log(video.grade, match.params.grade)
      return video.grade === Number(match.params.grade)
    })

    console.log(videos)

    const freeVideos = videos.filter(video => video.free)
    const myVideos = purchasedVideos
      .map(purchasedVideo =>
        videos.find(video => video.slug === purchasedVideo)
      )
      .filter(Boolean)

    console.log(freeVideos, myVideos)

    return (
      <div id="node-garden-container">
        <div className="container mx-auto">
          <h1 className="text-2xl text-white m-4 py-4 bg-indigo-light rounded-sm px-4">
            Grade {match.params.grade}
          </h1>
          {isLoggedIn ? (
            <div>
              <h1 className="text-2xl text-white border-b border-grey-lighter m-4 py-4">
                My Videos
              </h1>
              <div className="flex flex-row flex-wrap">
                {myVideos.map(video => {
                  return (
                    <Link
                      key={video.slug}
                      to={`/videos/${video.slug}`}
                      className="w-full lg:w-64 relative m-4"
                    >
                      <img src={video.thumbnail} alt="Video Thumbnail" />
                      <div className="absolute bg-vantablack opacity-75 w-full lg:w-64 pin flex flex-col justify-end items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          class="icon-play fill-current w-16 h-16"
                        >
                          <path
                            class="secondary text-white fill-current"
                            d="M15.51 11.14a1 1 0 0 1 0 1.72l-5 3A1 1 0 0 1 9 15V9a1 1 0 0 1 1.51-.86l5 3z"
                          />
                        </svg>
                        <div className="flex flex-col items-center w-full mb-4">
                          <h2 className="text-white text-sm uppercase tracking-wide font-bold p-2 text-center">
                            {video.name}
                          </h2>
                          <div>
                            <span className="text-white text-sm uppercase tracking-wide font-bold p-2">
                              {video.duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl text-white border-b border-grey-lighter m-4 py-4">
                Free Videos
              </h1>
              <div className="flex flex-row flex-wrap">
                {freeVideos.map(video => {
                  return (
                    <Link
                      key={video.slug}
                      to={`/videos/${video.slug}`}
                      className="w-full lg:w-64 relative m-4"
                    >
                      <img src={video.thumbnail} alt="Video Thumbnail" />
                      <div className="absolute bg-vantablack opacity-75 w-full lg:w-64 pin flex flex-col justify-end items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          class="icon-play fill-current w-16 h-16"
                        >
                          <path
                            class="secondary text-white fill-current"
                            d="M15.51 11.14a1 1 0 0 1 0 1.72l-5 3A1 1 0 0 1 9 15V9a1 1 0 0 1 1.51-.86l5 3z"
                          />
                        </svg>
                        <div className="flex flex-col items-center w-full mb-4">
                          <h2 className="text-white text-sm uppercase tracking-wide font-bold p-2 text-center">
                            {video.name}
                          </h2>
                          <div>
                            <span className="text-white text-sm uppercase tracking-wide font-bold p-2">
                              {video.duration}
                            </span>
                            <span className="text-white text-sm uppercase tracking-wide font-bold">
                              &middot;
                            </span>
                            {video.free ? (
                              <span className="text-green-darker text-xs uppercase tracking-wide font-bold bg-green-lighter rounded-sm p-1 mx-2">
                                FREE
                              </span>
                            ) : (
                              <span className="text-white text-sm uppercase tracking-wide font-bold bg-orange-lighter text-orange-darker p-1 mx-2 rounded-sm">
                                ₹ {video.price}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          )}

          <h1 className="text-2xl text-white border-b border-grey-lighter m-4 py-4">
            All Videos
          </h1>
          <div className="flex flex-row flex-wrap mb-4">
            {videos.map(video => {
              return (
                <Link
                  key={video.slug}
                  to={`/videos/${video.slug}`}
                  className="w-full lg:w-64 relative m-4"
                >
                  <img src={video.thumbnail} alt="Video Thumbnail" />
                  <div className="absolute bg-vantablack opacity-75 w-full lg:w-64 pin flex flex-col justify-end items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      class="icon-play fill-current w-16 h-16"
                    >
                      <path
                        class="secondary text-white fill-current"
                        d="M15.51 11.14a1 1 0 0 1 0 1.72l-5 3A1 1 0 0 1 9 15V9a1 1 0 0 1 1.51-.86l5 3z"
                      />
                    </svg>
                    <div className="flex flex-col items-center w-full mb-4">
                      <h2 className="text-white text-sm uppercase tracking-wide font-bold p-2 text-center">
                        {video.name}
                      </h2>
                      <div>
                        <span className="text-white text-sm uppercase tracking-wide font-bold p-2">
                          {video.duration}
                        </span>
                        <span className="text-white text-sm uppercase tracking-wide font-bold">
                          &middot;
                        </span>
                        {video.free ? (
                          <span className="text-green-darker text-xs uppercase tracking-wide font-bold bg-green-lighter rounded-sm p-1 mx-2">
                            FREE
                          </span>
                        ) : (
                          <span className="text-white text-sm uppercase tracking-wide font-bold bg-orange-lighter text-orange-darker p-1 mx-2 rounded-sm">
                            ₹ {video.price}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null
)(Videos)
