import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'

import { connect } from 'react-redux'

import { addToCart, removeFromCart } from '../redux/cart'

const canUserWatchVideo = (video, userVideos) =>
  userVideos.find(userVideo => userVideo === video.slug)

const mapStateToProps = ({ videos, cart, user }) => ({
  videos,
  items: cart.items,
  userVideos: user.videos
})

const mapDispatchToProps = {
  addToCart,
  removeFromCart
}

class Video extends Component {
  render() {
    const {
      videos,
      items,
      match,
      addToCart,
      removeFromCart,
      userVideos
    } = this.props
    const video = videos.find(video => video.slug === match.params.slug)

    return (
      <div className="container mx-auto h-screen p-4">
        <div className="flex flex-col">
          {video.free || canUserWatchVideo(video, userVideos) ? (
            <>
              <MobileView device={isMobile}>
                <ReactPlayer url={video.url} width={'100%'} height={'100%'} />
              </MobileView>
              <BrowserView device={isBrowser}>
                <ReactPlayer url={video.url} className="lg:w-2/3 my-8" />
              </BrowserView>
            </>
          ) : (
            <div className="w-full lg:w-2/3 relative my-8">
              <img
                className="w-full lg:w-2/3"
                src={video.thumbnail}
                alt="Video Thumbnail"
              />
              <div className="absolute bg-vantablack opacity-75 w-full lg:w-2/3 pin flex flex-col justify-end items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="icon-lock fill-current text-white w-16 h-16"
                >
                  <path
                    className="primary"
                    d="M5 10h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm6 6.73V18a1 1 0 0 0 2 0v-1.27a2 2 0 1 0-2 0z"
                  />
                  <path
                    className="secondary"
                    d="M12 19a1 1 0 0 0 1-1v-1.27A2 2 0 0 0 12 13v-3h3V7a3 3 0 0 0-6 0v3H7V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-7v-3z"
                  />
                </svg>
                <h1 className="text-white text-lg uppercase tracking-wide font-bold p-2 text-center m-4">
                  Unlock this Video for ₹ {video.price}
                </h1>
                {items.find(item => item.slug === match.params.slug) ? (
                  <button
                    onClick={removeFromCart.bind(this, video)}
                    className="bg-red-lighter text-red-darkest p-2 rounded-sm shadow-md uppercase text-base tracking-wide font-bold mt-4 mb-8"
                  >
                    Remove from Cart
                  </button>
                ) : (
                  <button
                    onClick={addToCart.bind(this, video)}
                    className="bg-green-light text-green-darker p-2 rounded-sm shadow-md uppercase text-base tracking-wide font-bold mt-4 mb-8"
                  >
                    Add to Cart
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <h1 className="border-b-2 border-grey-lighter text-black font-bold text-2xl py-4">
            {video.name}
          </h1>
          <div className="my-4">
            <span className="text-grey-darkest text-sm uppercase tracking-wide font-bold mr-2">
              {video.duration}
            </span>
            <span className="text-grey-darkest text-sm uppercase tracking-wide font-bold mr-2">
              &middot;
            </span>
            <span className="text-grey-darkest text-sm uppercase tracking-wide font-bold mr-2">
              {video.problems} Problems
            </span>
            <span className="text-grey-darkest text-sm uppercase tracking-wide font-bold mr-2">
              &middot;
            </span>
            <span className="text-grey-darkest text-sm uppercase tracking-wide font-bold mr-2">
              {video.marks} Marks
            </span>
          </div>
          <p className="text-base font-semibold text-grey leading-loose tracking-wide mb-12">
            {video.description}
          </p>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Video)
