import videos from '../data/videos.json'

const ACTION_HANDLERS = {}

const initialState = videos

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
