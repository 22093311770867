import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist'
import createActionBuffer from 'redux-action-buffer'
import { REHYDRATE } from 'redux-persist/constants'
import thunk from 'redux-thunk'

import './custom.css'

import user from './redux/user'
import videos from './redux/videos'
import cart from './redux/cart'

import App from './App'
import ScrollToTop from './ScrollToTop'
// import registerServiceWorker from './registerServiceWorker'

const middleware = [thunk, createActionBuffer(REHYDRATE)]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    user: user,
    videos: videos,
    cart: cart
  }),
  composeEnhancers(applyMiddleware(...middleware), autoRehydrate())
)

persistStore(store, {
  whitelist: ['user', 'cart']
})

const root = document.getElementById('root')

if (root.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    root
  )
} else {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    root
  )
}

// registerServiceWorker()
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    if (registrations.length) {
      for (let registration of registrations) {
        registration.unregister()
      }
    }
  })
}
