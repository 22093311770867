import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { BrowserView, isBrowser } from 'react-device-detect'

import { connect } from 'react-redux'

import { addToCart, removeFromCart, checkoutCart } from '../redux/cart'

const mapStateToProps = ({ cart, user }) => ({
  items: cart.items,
  isLoggedIn: user.isLoggedIn
})

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
  checkoutCart
}

class Cart extends Component {
  redirectOnSuccess() {
    this.props.history.push('/videos')
  }
  render() {
    const {
      items,
      removeFromCart,
      checkoutCart,
      isLoggedIn,
      openAuthModal
    } = this.props
    const total = items.reduce((sum, currentItem) => sum + currentItem.price, 0)

    return (
      <div className="container mx-auto h-screen px-4">
        <div className="">
          <h1 className="text-black text-3xl font-bold py-4 border-b-2 border-grey-lighter">
            Cart
          </h1>
          <div className="flex flex-col mt-8">
            {items.map(item => {
              return (
                <div
                  key={item.slug}
                  className="flex flex-row items-center justify-around mr-16 my-4"
                >
                  <BrowserView device={isBrowser}>
                    <div className="w-48 mr-16">
                      <img alt="Thumbnail" src={item.thumbnail} />
                    </div>
                  </BrowserView>
                  <Link
                    to={`/videos/${item.slug}`}
                    className="text-base no-underline text-black font-semibold w-80 uppercase"
                  >
                    {item.name}
                  </Link>
                  <div className="text-black text-base uppercase font-semibold mr-8 lg:mx-16">
                    ₹{item.price}
                  </div>
                  <img
                    onClick={removeFromCart.bind(this, item)}
                    alt="Remove"
                    src="images/close.svg"
                    className="w-4 h-4 ml-auto mr-0"
                  />
                </div>
              )
            })}
          </div>
          <div className="">
            <div className="flex flex-col items-start justify-start my-4">
              <h4 className="text-lg text-black font-bold my-2">
                Total: {total} INR
              </h4>
              <button
                onClick={() => {
                  if (isLoggedIn) {
                    return checkoutCart(
                      total,
                      this.redirectOnSuccess.bind(this)
                    )
                  } else {
                    openAuthModal('You need to be logged in to checkout')
                  }
                }}
                className="text-base font-bold uppercase p-2 rounded-sm bg-green shadow-md text-white my-2"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Cart)
)
