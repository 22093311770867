import React, { Component } from 'react'

class RefundCancellationPolicy extends Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col flex-grow p-8">
        <h1 className="text-3xl tracking-wide text-grey-darkest m-4">
          Refund Policy
        </h1>
        <h2 className="text-xl text-grey-darkest mx-4 mt-8 font-semibold leading-loose border-b-2 border-grey-lightest pb-4 mb-4">
          Refunds
        </h2>
        <p className="text-lg text-grey-darker mx-4 mb-4 font-semibold leading-loose">
          We do not offer refunds or returns. All sales and subscriptions are
          final.
        </p>
      </div>
    )
  }
}

export default RefundCancellationPolicy
