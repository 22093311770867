import React, { Component } from 'react'

class Home extends Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col flex-grow p-8">
        <h1 className="text-3xl tracking-wide text-grey-darkest m-4 border-b-2 border-grey-lightest pb-4">
          About Jayesh Bahirwani
        </h1>
        <p className="text-lg text-grey-darker m-4 font-semibold leading-loose">
          Jayesh Bahirwani (B.Sc. B.Ed.) taught Math for grades 9 & 10 at
          Lilavatibai Podar Senior Secondary School, Mumbai from 2003 to 2010
          and served as the Head of Department of Mathematics from 2006 to 2010.
          From 2010 to 2016, he has served as visiting faculty for academically
          gifted students at the same school.
        </p>
        <p className="text-lg text-grey-darker m-4 font-semibold leading-loose">
          Jayesh has taught students for IIT Foundation, NTSE, NSTSE, SOF, ISFO
          (grades 8 to 10) and for Management Entrance exams for 2 years.
        </p>
        <p className="text-lg text-grey-darker m-4 font-semibold leading-loose">
          Currently, he conducts private tuitions for ICSE Math (grades 9 & 10).
        </p>
      </div>
    )
  }
}

export default Home
