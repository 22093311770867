import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Home extends Component {
  render() {
    return (
      <div className="bg-blue-darkest flex flex-grow">
        <div
          className="flex flex-col justify-start items-center w-full mx-auto"
          id="node-garden-container"
        >
          <div className="lg:text-5xl text-4xl text-white tracking-wide font-normal mb-4 mt-12 lg:mt-20 text-center px-4">
            Learn from <span className="font-bold">the best</span>
          </div>
          <div className="lg:text-3xl text-2xl text-blue font-normal mb-12 lg:mb-16 text-center leading-tight px-4">
            Math Crash Course for ICSE Ninth and Tenth Graders
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <Link
              to="/grades/9"
              className="text-green-darker uppercase tracking-wide bg-green-light p-4 no-underline font-bold m-4 shadow-md rounded-sm w-80 text-center"
            >
              Start Learning for Grade 9
            </Link>
            <Link
              to="/grades/10"
              className="text-green-darker uppercase tracking-wide bg-green-light p-4 no-underline font-bold m-4 shadow-md rounded-sm w-80 text-center"
            >
              Start Learning for Grade 10
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
