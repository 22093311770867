import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
  render() {
    return (
      <div className="flex flex-col justify-between items-center bg-blue-darkest p-2 lg:p-3 lg:h-24">
        <div className="flex flex-row flex-wrap items-center justify-center">
          <Link
            to="/terms"
            className="text-xs text-grey tracking-wide font-semibold no-underline uppercase p-2 border border-grey rounded-sm m-1 lg:m-2"
          >
            Terms &amp; Conditions
          </Link>
          <Link
            to="/privacy"
            className="text-xs text-grey tracking-wide font-semibold no-underline uppercase p-2 border border-grey rounded-sm m-1 lg:m-2"
          >
            Privacy
          </Link>
          <Link
            to="/refunds"
            className="text-xs text-grey tracking-wide font-semibold no-underline uppercase p-2 border border-grey rounded-sm m-1 lg:m-2"
          >
            Refunds &amp; Cancellations
          </Link>
        </div>
        <div className="text-xs text-grey tracking-wide font-semibold m-2">
          &copy; {new Date().getFullYear()}, JB Tutorials. All Rights Reserved.
        </div>
      </div>
    )
  }
}

export default Footer
